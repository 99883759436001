import Vue from 'vue'
import Router from 'vue-router'
const index = () => import('../view/index.vue')
const home = () => import('../view/home')
const walkIn = () => import('../view/walkIn')
const msg = () => import('../view/msg')

const news = () => import('../view/news')
const details = () => import('../view/news/details')
const serve = () => import('../view/serve')


const insurance = () => import('../view/insurance')
const insureDetail = () => import('../view/insurance/details')

const connect = () => import('../view/connect')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      redirect: '/home'
    },
    {
      path: '',
      name: 'Home',
      component: index,
      children: [
        {
          path: '/home',
          component: home
        },
        {
          path: '/walkIn',
          component: walkIn
        },
        {
          path: '/msg',
          component: msg,
        },
        {
          path: '/news',
          component: news,
        },
        {
          path: '/news/details',
          component: details,
        },
        {
          path: '/serve',
          component: serve,
        },
        {
          path: '/insurance',
          component: insurance,
        },
        {
          path: '/insurance/details',
          component: insureDetail,
        },
        {
          path: '/connect',
          component: connect
        }

      ]
    }
  ]
})
